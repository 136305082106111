

import { Global } from "@/support/GlobalData";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { defineComponent } from "vue";

export default defineComponent({
    name: "SimpleKeyboard",
    //emits: [ "showing", "hiding", "on-change", "on-key-press", "accept" ],
    props: {
        keyboardClass: { type: String, default: "simple-keyboard" },
        input: { type: String },
        layoutName: { type: String, required: true },
        layout: { type: Object, required: true },
        display: { type: Object, required: true },
        inputName: { type: String, required: false, default: "" },
    },
    data() {
        return {
            shown: false,
            keyboard: null! as Keyboard,
            timeout: 0,
        };
    },
    methods: {
        showKeyboard(): void {
            killAllSimpleKeyboards(false);
            (this.$refs.simplekeyboarddivref as HTMLDivElement).style.display = "";
            SimpleKeyboardSingleton = this;
            this.shown = true;
            this.$emit("showing");
        },
        hideKeyboard(sendHidingEvent: boolean): void {
            if (this.timeout) clearTimeout(this.timeout);
            const kbd = this.$refs.simplekeyboarddivref as HTMLDivElement;
            if (!kbd) return;
            kbd.style.display = "none";
            if (!this.shown) return;
            this.shown = false;
            SimpleKeyboardSingleton = null;
            if (sendHidingEvent) {
                this.timeout = setTimeout((): void => {
                    // Delay hiding event so mouseup/click are processed before we annonce that the keyboard is gone.
                    // This can be used by pages which have buttons below the keyboard's Accept button, which would be
                    // auto-clicked after the Accept button is clicked. Pages can disable those buttons until they receive
                    // the hiding event (which is delayed) to enable those buttons.
                    this.$emit("hiding");
                }, 1500) as any as number;
            }
        },
        onChange(input: string): void {
            this.$emit("on-change", input);
        },
        onKeyPress(button: string): void {
            this.$emit("on-key-press", button);
            // If you want to handle the shift and caps lock buttons, a "shift" layout has to be
            // provided in the layout property.
            if (button === "{shift}" || button === "{lock}") {
                this.handleShift();
            } else if (button === "{enter}") {
                this.hideKeyboard(true);
                this.$emit("accept");
            }
        },
        handleShift(): void {
            const currentLayout = this.keyboard.options.layoutName;
            const shiftToggle = currentLayout === "default" ? "shift" : "default";
            this.keyboard.setOptions({
                layoutName: shiftToggle
            });
        },

    },
    mounted() {
        this.$nextTick((): void => {
            const sk = this.$refs.simplekeyboarddivref as HTMLDivElement;
            const kbd = sk.querySelector("div");
            if (!kbd) return;
            this.keyboard = new Keyboard(kbd, {
                onChange: this.onChange,
                onKeyPress: this.onKeyPress,
                layoutName: this.layoutName,
                layout: this.layout,
                display: this.display,
                inputName: this.inputName.length ? this.inputName : undefined,
            });
            this.hideKeyboard(false);
        });

    },
    unmounted() {
        this.keyboard.destroy();
        this.keyboard = null!;
    },
    watch: {
        input(input: string) {
            this.keyboard.setInput(input);
        }
    },
});

// This is a global event handler, instantiated once, which handles all simple-keyboards.
// It closes all simple-keyboards when not clicking on an input field (.global-simple-keyboard-input) or
// a simple-keyboard button.
if (Global.DetailLog) console.log("Registering document.addEventListener for simple-keyboard");
document.addEventListener("click", (event: MouseEvent): void => {
    // Hide the keyboard when you're not clicking it or when not clicking on an input field handled by simple-keyboard
    try {
        if (!event || !event.target || !event.target) return;
        const elem = event.target as HTMLElement;
        const className = elem.className;
        if (!className) return;
        if (!className.includes("global-simple-keyboard") &&
            !className.includes("global-simple-keyboard-input") &&
            !className.includes("hg-button") &&
            !className.includes("hg-row") &&
            !className.includes("simple-keyboard")) {

            killAllSimpleKeyboards(true);
        }
    } catch(e:any) {
        // ignore errors
        // When invoking a static page (like Wifi/Debug on the Auggie device client, this can crash during the transition
        // to the static page, probably as a result of event bubbling. IDK.
    }
});

let SimpleKeyboardSingleton: any = null;

export function killAllSimpleKeyboards(sendHidingEvent: boolean): void {
    if (SimpleKeyboardSingleton)
        SimpleKeyboardSingleton.hideKeyboard(sendHidingEvent);
}

